<template>
  <a-modal
    @cancel="cancel"
    v-model="visible"
    title="添加商品"
    width="900px"
    :maskClosable="false"
    :keyboard="false"
  >
    <div class="dialogAdd">
      <div class="quick-option">
        <a-button-group>
          <a-upload :beforeUpload="beforeUpload" :showUploadList="false">
            <a-button size="small" type="primary">批量导入商品ID</a-button>
          </a-upload>
          <a-button size="small" type="primary"
            ><a
              href="https://oss.zheyi360.com/s2b-template/template.xlsx"
              target="_blank"
              download="template.xlsx"
              >下载模板</a
            ></a-button
          >
        </a-button-group>
      </div>
      <div class="table">
        <div class="table-head table-tr">
          <div>商品ID</div>
          <div>商品名称</div>
          <div>供应商</div>
          <div>佣金率</div>
          <div>服务费率</div>
          <div></div>
        </div>
        <div class="table-body">
          <div class="table-tr" v-for="(item, key) in goods" :key="key">
            <div>
              <af-input
                v-model="item.item_id"
                format="integer"
                :maxLength="50"
                @blur="updateGood(item)"
              />
            </div>
            <div>{{ item.goods && item.goods.item_name }}</div>
            <div>{{ item.goods && item.goods.supplier }}</div>
            <div>
              <af-input
                debug
                v-model="item.item__external_commission_rate"
                format="number"
                addon-after="%"
                :maxLength="3"
              />
            </div>
            <div>
              <af-input
                v-model="item.service_rate"
                format="number"
                addon-after="%"
                :maxLength="3"
              />
            </div>
            <div><a @click="goods.splice(key, 1)">删除</a></div>
          </div>
        </div>
        <div class="add">
          <a-button type="primary" @click="addGood">添加商品</a-button>
        </div>
      </div>
      <div class="mask" v-if="isUpload"></div>
    </div>

    <template slot="footer">
      <a-button @click="visible = false">取消</a-button>
      <a-button type="primary" :loading="isUpload" @click="submit"
        >{{ isUpload ? uploadText : '确定' }}
      </a-button>
    </template>
  </a-modal>
</template>
<script>
import { campaign_goods_info, campaign_product_add } from '@/service/operate';
import { message } from 'ant-design-vue';
import XLSX from 'xlsx';

export default {
  data() {
    return {
      visible: false,
      goods: [],
      isUpload: false,
      uploadText: '处理中(0/10)',
    };
  },
  mounted() {
    // setTimeout(() => {
    //   this.visible = true;
    // });
  },
  methods: {
    show() {
      this.visible = true;
    },
    addGood() {
      this.goods.push({});
    },
    updateGood(item) {
      return Promise.resolve()
        .then(() => {
          if (!item.item_id) {
            return {
              res: {
                res: {
                  success: false,
                },
              },
            };
          } else {
            return campaign_goods_info({
              item_id: item.item_id,
            });
          }
        })
        .then(res => {
          if (res.res.success) {
            if (res.res.data.results.length > 0) {
              const good = res.res.data.results[0];
              this.$set(item, 'goods', good);
              this.$set(
                item,
                'item__external_commission_rate',
                good.external_commission_rate / 100,
              );
            } else {
              this.$set(item, 'goods', undefined);
              this.$set(item, 'item__external_commission_rate', '');
              message.error('当前商品信息不存在,请先完善商品信息');
            }
          }
        });
    },
    async parseXLSX(file) {
      return new Promise((resolve, reject) => {
        if (file.type.indexOf('sheet') === -1) {
          return reject(new Error('文件格式错误'));
        }
        const reader = new FileReader();
        reader.onload = e => {
          try {
            let binary = '';
            const bytes = new Uint8Array(e.target.result);
            const length = bytes.byteLength;
            for (let i = 0; i < length; i++) {
              binary += String.fromCharCode(bytes[i]);
            }
            const wb = XLSX.read(binary, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const arr = XLSX.utils.sheet_to_json(wb.Sheets[wsname]);
            if (arr.length > 0 && arr[0].商品ID === undefined) {
              reject(new Error('非法模板'));
            } else {
              resolve(arr);
            }
          } catch (e) {
            reject();
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
    async beforeUpload(file) {
      this.parseXLSX(file)
        .then(async arr => {
          for (let i = 0; i < arr.length; i++) {
            const tmp = arr[i];
            const good = {
              item_id: tmp['商品ID'],
            };
            await this.updateGood(good);
            if (tmp['佣金率']) {
              good.item__external_commission_rate = tmp['佣金率'];
              good.service_rate = tmp['服务费率'];
            }
            this.goods.push(good);
          }
        })
        .catch(e => {
          message.error(e.message);
        });
      return false;
    },
    cancel() {},
    async submit() {
      if (this.isUpload) return;
      const goods = this.goods.filter(it => {
        return it.goods !== undefined || it.item_id;
      });
      this.goods = goods;
      const length = goods.length;
      if (length === 0) {
        message.info('请添加商品');
        return;
      }
      const campaign_id = this.$router.currentRoute.query.id;
      // if (location.href !== '') return;
      this.isUpload = true;

      this.uploadText = `处理中(0/${length})`;
      let success = 0;
      for (let i = 0; i < this.goods.length; i++) {
        this.uploadText = `处理中(${i + 1}/${length})`;
        try {
          const tmp = this.goods[i];
          if (!tmp.goods || !tmp.item__external_commission_rate) continue;
          const res = await campaign_product_add({
            campaign_id,
            item_id: tmp.goods.id,
            item__external_commission_rate: tmp.item__external_commission_rate * 100,
            service_rate: tmp.service_rate,
          });
          if (res.res.success) {
            success++;
            this.goods.splice(i, 1);
            i--;
          }
        } catch (ex) {
          console.log('ex', ex);
        }
      }
      this.isUpload = false;
      if (success !== length) {
        message.error(`本次成功添加 ${success} 商品`);
      } else {
        this.visible = false;
        message.success(`本次成功添加 ${success} 商品`);
      }
      this.$emit('refresh');
    },
  },
};
</script>
<style lang="less" scoped>
.dialogAdd {
  position: relative;
  margin: -24px;
  padding: 24px;
}

.table-body {
  max-height: 400px;
  overflow: hidden;
  overflow-y: auto;
}

.mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.quick-option {
  display: flex;
  padding-bottom: 10px;
}

.table {
  display: grid;
  grid-column-gap: 10px;

  .table-head {
    text-align: center;

    &.table-tr div {
      padding: 10px 0px;
    }
  }

  .table-tr {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 100px 100px 100px;
    text-align: center;
    align-items: center;

    & > div {
      padding: 10px 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:nth-child(1) {
      background: #fafafa;
      border-bottom: 1px solid #e8e8e8;
      font-weight: bold;
      padding: 6px 0;
    }
  }

  .add {
    padding-top: 10px;
  }
}
</style>
