<template>
  <a-modal v-model="visible" title="编辑商品" width="700px">
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <div class="goods-info">
        <div class="tl">商品ID</div>
        <div>商品名称</div>
        <div>供应商</div>
        <div>佣金率</div>
        <div>服务费率</div>
        <div class="tr">折一上架状态</div>
        <div class="tl">{{ formData.item_id }}</div>
        <div>{{ formData.item__item_name }}</div>
        <div>{{ formData.item__supplier }}</div>
        <div>{{ formData.item__external_commission_rate }}</div>
        <div>{{ formData.service_rate }}%</div>
        <div class="tr">{{ formData.item__is_on_sale === 1 ? '已上架' : '未上架' }}</div>
      </div>
      <div class="goods-link">
        <div class="title">团长活动商品链接</div>
        <a-form-item :wrapper-col="{ span: 24 }">
          <a-input
            type="textarea"
            style="width: 100%"
            v-decorator="[
              'campaign_link',
              {
                rules: [{ required: true, message: '请填写团长活动商品链接' }],
              },
            ]"
          />
        </a-form-item>
      </div>
    </a-form>
    <template slot="footer">
      <a-button @click="visible = false">取消</a-button>
      <a-button type="primary" @click="submit(2)">保存</a-button>
      <!--      <a-button type="primary" @click="submit(1)">保存并上架</a-button>-->
    </template>
  </a-modal>
</template>
<script>
import { campaign_product_edit } from '@/service/operate';
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      formData: {},
      visible: false,
    };
  },
  methods: {
    show(row) {
      this.formData = row;
      this.visible = true;
      this.$nextTick(() => {
        this.form.setFieldsValue({
          campaign_link: row.campaign_link,
        });
      });
    },
    submit(is_on_sale) {
      this.form
        .validateFields()
        .then(value => {
          return campaign_product_edit({
            ...this.formData,
            campaign_link: value.campaign_link,
            is_on_sale,
          })
            .then(res => {
              if (!res.res.success) {
                throw new Error('失败');
              }
            })
            .then(() => {
              this.formData.campaign_link = value.campaign_link;
              this.visible = false;
              this.$emit('close');
            });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
.goods-info {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 10px;
  text-align: center;
  .tl {
    text-align: left;
  }
  .tr {
    text-align: right;
  }
  div {
    &:nth-child(-n + 5) {
      font-weight: bold;
    }
  }
}
.goods-link {
  .title {
    font-weight: bold;
    padding: 18px 0;
  }
}
.ant-form-item {
  /deep/ textarea {
    resize: none;
  }
}
</style>
