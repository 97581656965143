<template>
  <div id="specialManage">
    <a-tabs @change="tabChangeCallback" type="card">
      <a-tab-pane tab="活动商品" key="1"></a-tab-pane>
      <a-tab-pane tab="商品提报" key="2"></a-tab-pane>
    </a-tabs>
    <a-form class="filter-condition-form" layout="inline">
      <a-form-item>
        <a-input v-model.trim="searchParams.search" allowClear placeholder="商品ID/商品名称" />
      </a-form-item>
      <a-form-item>
        <a-input v-model.trim="searchParams.campaign_link" allowClear placeholder="团长活动链接" />
      </a-form-item>
      <a-form-item>
        <a-select v-model="searchParams.is_on_sale" style="width: 120px">
          <a-select-option value="">上架状态</a-select-option>
          <a-select-option value="1">已上架</a-select-option>
          <a-select-option value="2">未上架</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-select v-if="selectTab === 1" v-model="searchParams.douyin_status" style="width: 120px">
          <a-select-option value="">合作状态</a-select-option>
          <a-select-option value="1">合作中</a-select-option>
          <a-select-option value="5">合作中取消</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item style="">
        <goods-range
          title="佣金率"
          :isneedbaifenhao="true"
          v-model="searchParams.external_commission_rate"
        />
      </a-form-item>
      <a-form-item style="">
        <goods-range
          title="服务费率"
          :isneedbaifenhao="true"
          v-model="searchParams.external_commission_server_rate"
        />
      </a-form-item>
      <a-form-item>
        <a-button @click="search" type="primary" html-type="submit" :loading="loading"
          >查询
        </a-button>
      </a-form-item>
      <div class="ptb-10">
        <a-button type="primary" v-if="selectTab === 2" @click="$refs.dialogAddGoodsRef.show()"
          >添加商品</a-button
        >
        <a-button type="primary" v-if="selectTab === 1" @click="campaign_tatch_update_goods_sale(1)"
          >批量上架</a-button
        >
        <a-button type="primary" v-if="selectTab === 1" @click="campaign_tatch_update_goods_sale(2)"
          >批量下架</a-button
        >
        <a-button type="primary" v-if="selectTab === 1" @click="campaign_tatch_update_goods_sync()"
          >同步活动商品</a-button
        >
        <!--        <a-button type="primary" v-if="selectTab === 1" @click="handleExport()">商品导出</a-button>-->
      </div>
    </a-form>
    <template v-if="selectTab === 1">
      <a-table
        :scroll="{ x: 1200, y: tableScrollY }"
        v-auth="'campaign_manage'"
        :rowKey="record => record.id"
        :columns="Columns"
        :dataSource="list"
        :pagination="pagination"
        :loading="listLoading"
        :locale="{ filterConfirm: '暂无数据' }"
        @change="handleTableChange"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      >
        <template slot="kr_status" slot-scope="record">
          <a-tag v-if="record.item__is_on_sale === 1 && record.douyin_status === 1" color="green"
            >已上架</a-tag
          >
          <a-tag v-else color="red">未上架</a-tag>
        </template>
        <template slot="operation" slot-scope="record">
          <!--<a-button class="mr-10" @click="$refs.dialogEditGood.show(record)" size="small"
            >编辑
          </a-button>-->
          <a-button
            v-if="record.douyin_status === 1"
            class="mr-10"
            size="small"
            @click="campaign_update_goods_sale(record)"
            v-auth="'operate_manage_special_modify'"
            >{{ record.item__is_on_sale === 1 ? '下架' : '上架' }}
          </a-button>
          <!-- <a-button
            size="small"
            @click="campaign_product_delete(record)"
            v-auth="'operate_manage_special_modify'"
            >删除
          </a-button>-->
        </template>
        <template slot="service_rate" slot-scope="record"> {{ record.service_rate }}%</template>
      </a-table>
    </template>
    <template v-else>
      <a-table
        :scroll="{ x: 1200, y: tableScrollY }"
        v-auth="'campaign_manage'"
        :rowKey="record => record.id"
        :columns="sendColumns"
        :dataSource="list"
        :pagination="pagination"
        :loading="listLoading"
        :locale="{ filterConfirm: '暂无数据' }"
        @change="handleTableChange"
      >
        <template slot="kr_status" slot-scope="record">
          <a-tag v-if="record.item__is_on_sale === 1" color="green">已上架</a-tag>
          <a-tag v-else color="red">未上架</a-tag>
        </template>
        <template slot="operation" slot-scope="record">
          <a-button class="mr-10" @click="$refs.dialogEditGood.show(record)" size="small"
            >编辑
          </a-button>
          <!--  <a-button
            class="mr-10"
            size="small"
            @click="campaign_update_goods_sale(record)"
            v-auth="'operate_manage_special_modify'"
          >{{ record.item__is_on_sale === '未上架' ? '上架' : '下架' }}
          </a-button>
          <a-button
            size="small"
            @click="campaign_product_delete(record)"
            v-auth="'operate_manage_special_modify'"
          >删除
          </a-button>-->
        </template>
        <template slot="service_rate" slot-scope="record"> {{ record.service_rate }}%</template>
      </a-table>
    </template>
    <dialog-add-goods ref="dialogAddGoodsRef" @refresh="refresh" />
    <dialog-edit-good ref="dialogEditGood" @close="refresh" />
  </div>
</template>

<script>
import {
  campaign_product_list,
  campaign_product_sync_list,
  campaign_product_delete,
  campaign_update_goods_sale,
  campaign_tatch_update_goods_sale,
  exportKrGoodsList,
} from '@/service/operate';
import dialogAddGoods from './dialogAddGoods';
import dialogEditGood from './dialogEditGood';
import { message } from 'ant-design-vue';
import GoodsRange from '@/components/admin/goods/goods-range';

/**
 * @Author: 矢车
 * @Date: 2020-07-08 10:08:51
 * @Description: 表格显示字段配置
 */
const Columns = [
  {
    title: '商品ID',
    fixed: 'left',
    dataIndex: 'item__item_id_id',
    width: 190,
  },
  {
    title: '商品名称',
    dataIndex: 'item__item_name',
    width: 330,
  },
  {
    title: '供应商',
    dataIndex: 'item__supplier',
    width: 130,
  },
  {
    title: '佣金率',
    dataIndex: 'item__external_commission_rate',
    align: 'center',
    width: 100,
  },
  {
    title: '服务费率',
    align: 'center',
    width: 100,
    scopedSlots: { customRender: 'service_rate' },
  },
  {
    title: '团长活动链接',
    dataIndex: 'campaign_link',
    width: 400,
  },
  {
    title: '折一上架状态',
    align: 'center',
    width: 130,
    scopedSlots: { customRender: 'kr_status' },
  },
  {
    title: '合作状态',
    dataIndex: 'item__is_sale',
    align: 'center',
    width: 130,
  },
  {
    title: '操作',
    align: 'center',
    fixed: 'right',
    width: 100,
    scopedSlots: { customRender: 'operation' },
  },
];
const sendColumns = [
  {
    title: '商品ID',
    fixed: 'left',
    dataIndex: 'item__item_id_id',
    width: 190,
  },
  {
    title: '商品名称',
    dataIndex: 'item__item_name',
    width: 330,
  },
  {
    title: '供应商',
    dataIndex: 'item__supplier',
    width: 130,
  },
  {
    title: '佣金率',
    dataIndex: 'item__external_commission_rate',
    align: 'center',
    width: 100,
  },
  {
    title: '服务费率',
    align: 'center',
    width: 100,
    scopedSlots: { customRender: 'service_rate' },
  },
  {
    title: '团长活动链接',
    dataIndex: 'campaign_link',
    width: 400,
  },
  {
    title: '折一上架状态',
    align: 'center',
    width: 130,
    scopedSlots: { customRender: 'kr_status' },
  },
  {
    title: '操作',
    align: 'center',
    fixed: 'right',
    width: 100,
    scopedSlots: { customRender: 'operation' },
  },
];
export default {
  name: 'special',
  components: {
    dialogEditGood,
    dialogAddGoods,
    GoodsRange,
  },
  data() {
    return {
      // 选中的数据
      selectedRows: [],
      selectedRowKeys: [],
      tableScrollY: 500,
      selectTab: 1,
      loading: false,
      activitysearchParams: {
        search: '',
        campaign_link: '',
        is_on_sale: '',
        douyin_status: '',
        external_commission_rate: [],
        external_commission_server_rate: [],
      },
      sendsearchParams: {
        search: '',
        campaign_link: '',
        is_on_sale: '',
        douyin_status: '',
        external_commission_rate: [],
        external_commission_server_rate: [],
      },
      searchParams: {
        search: '',
        campaign_link: '',
        is_on_sale: '',
        douyin_status: '',
        external_commission_rate: [],
        external_commission_server_rate: [],
      }, //  搜索字段
      lastSearch: {},
      visible: true,
      form: this.$form.createForm(this, { name: 'coordinated' }),
      isOperateShop: false,
      shopName: '',
      shopCode: '',
      recordRow: '', //  点击某一行的数据
      Columns,
      sendColumns,
      listLoading: true,
      list: [],
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize,
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) => (this.pagination.pageSize = pageSize),
      },
      // （0:待审核 1:审核通过 2:审核拒绝 3:建议二次报名 4:未合作取消，5:合作中取消）
      hezuolist: ['待审核', '合作中', '审核拒绝', '建议二次报名', '未合作取消', '合作中取消'],
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 385;
  },
  mounted() {
    this.lastSearch.campaign_id = this.$router.currentRoute.query.id;
    this.getTopicList();
  },
  methods: {
    // 选中不选中行
    onSelectChange(selectedRowKeys, selectedRows) {
      // this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      var arr = [];
      selectedRows.forEach(item => {
        if (item.douyin_status === 1) {
          arr.push(item.id);
        }
      });
      this.selectedRowKeys = arr;
    },
    // 导出
    async handleExport() {
      const obj = {};
      Object.keys(this.searchParams.value).forEach(v => {
        if (this.searchParams.value[v] !== null) {
          obj[v] = this.searchParams.value[v];
        }
      });
      this.listLoading = true;
      const { res } = await exportKrGoodsList(obj);
      this.listLoading = false;
      if (res.success) {
        this.$message.success('导出成功');
        location.href = res.data.detail_url;
      }
    },
    // tab切换回调
    tabChangeCallback(e) {
      this.selectTab = Number(e);
      if (this.selectTab === 2) {
        this.activitysearchParams = this.searchParams;
        this.searchParams = this.sendsearchParams;
      }
      if (this.selectTab === 1) {
        this.sendsearchParams = this.searchParams;
        this.searchParams = this.activitysearchParams;
      }
      this.pagination.current = 1;
      this.getTopicList(this.searchParams);
    },
    refresh() {
      this.getTopicList();
    },

    //  搜索
    search() {
      this.pagination.current = 1;
      //  为 0 不传当前字段
      this.getTopicList(this.searchParams);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
        }
      });
    },
    handleSearchCallback(searchParams) {
      this.searchParams = searchParams;
      this.getTopicList();
    },
    // 获取专题列表
    async getTopicList(search = {}) {
      this.listLoading = true;
      const oldparams = {
        ...this.lastSearch,
        ...search,
        item__external_commission_rate_min: String(
          this.searchParams.external_commission_rate[0] || '',
        ),
        item__external_commission_rate_max: String(
          this.searchParams.external_commission_rate[1] || '',
        ),
        service_rate_min: String(this.searchParams.external_commission_server_rate[0] || ''),
        service_rate_max: String(this.searchParams.external_commission_server_rate[1] || ''),
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
      };
      oldparams.external_commission_rate = [];
      oldparams.external_commission_server_rate = [];
      let params;
      if (this.selectTab === 2) {
        params = {
          ...oldparams,
          source: 1,
        };
      } else {
        params = {
          ...oldparams,
        };
      }
      const { err, res } = await campaign_product_list(params);
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          this.lastSearch = oldparams;
          this.list = res.data.results.map(item => {
            item.item__external_commission_rate = `${
              (item.item__external_commission_rate / 100).toFixed(2) - 0
            }%`;
            item.item__is_sale = this.hezuolist[Number(item.douyin_status)];
            // item.item__is_on_sale = item.item__is_on_sale === 1 ? '已上架' : '未上架';

            return item;
          });
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.warning(res.message);
        }
      }
    },

    // 分类
    handleTableChange(pagination) {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      if (pagination) this.pagination.current = pagination.current;
      this.getTopicList(
        this.searchParams.search,
        this.searchParams.special_type == '0' ? '' : this.searchParams.special_type,
        this.searchParams.status == '0' ? '' : this.searchParams.status,
        this.searchParams.goods_channel,
        this.searchParams.custom_cat,
      );
    },
    campaign_tatch_update_goods_sale(is_on_sale) {
      if (this.selectedRowKeys.length < 1) {
        message.warn('请先勾选商品再提交！！！');
        return;
      }
      campaign_tatch_update_goods_sale({
        campaign_id: this.lastSearch.campaign_id,
        is_on_sale: is_on_sale,
        id_list: this.selectedRowKeys,
      }).then(res => {
        if (res.res.success) {
          message.success('成功');
          this.refresh();
        }
      });
    },
    campaign_update_goods_sale(row) {
      campaign_update_goods_sale({
        item_ids: [row.item__item_id_id],
        is_on_sale: row.item__is_on_sale === 1 ? 2 : 1,
      }).then(res => {
        if (res.res.success) {
          message.success('成功');
          this.refresh();
        }
      });
    },
    // 同步商品
    async campaign_tatch_update_goods_sync() {
      const params = {
        campaign_id: this.lastSearch.campaign_id,
      };
      this.listLoading = true;
      const { err, res } = await campaign_product_sync_list(params);
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          this.getTopicList();
          this.$message.success(
            res.data.detail || '商品同步时间较长，请难心等待，稍后请刷新页面。',
          );
        }
        /*  else {
          this.$message.warning(res.message || '商品同步失败，请重新点击同步活动商品！！！');
        }*/
      }
    },
    campaign_product_delete(row) {
      this.$confirm({
        title: '删除商品',
        content: () => `确认要删除商品?`,
        onOk: () => {
          campaign_product_delete({ id: row.id }).then(res => {
            if (res.res.success) {
              message.success('删除成功');
              this.refresh();
            }
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
#specialManage {
  .ptb-10 {
    padding: 10px 0;
    display: grid;
    grid-column-gap: 12px;
    grid-template-columns: repeat(auto-fit, minmax(auto, 120px));
  }
}

.form_btn {
  display: flex;
  justify-content: center;
}

.mr-30 {
  margin-right: 30px;
}

.mr-10 {
  margin-right: 10px;
}
</style>
